<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars"/>
      <SumaryFinalReportPdf
        v-if="pageInfo.Report==0"
        :Condition="Condition"
        :TpVessel="pageInfo.TpVessel"
        :CraneAlias="pageInfo.CraneAlias"
        :Header="Header"
        :Embarked="Embarked"
        :Movement="Movement"
        :Lapse="Lapse"
        :Position="Position"
        :ChartHold="pageInfo.ChartHold"
        :ChartTon="pageInfo.ChartTon"
        :itinerarySelected="pageInfo.itinerarySelected"
      />
      <SumaryDailyReportPdf
        v-if="pageInfo.Report==1"
        :Condition="Condition"
        :Total="Total"
        :TpVessel="pageInfo.TpVessel"
        :Day="pageInfo.Date"
        :StartsDate="pageInfo.StartsDate"
        :CraneAlias="pageInfo.CraneAlias"
        :Header="Header"
        :Embarked="Embarked"
        :Movement="Movement"
        :Turn="Turn"
        :Lapse="Lapse"
        :Position="Position"
        :itinerarySelected="pageInfo.itinerarySelected"
      />
    </div>
  </transition>
</template>

<script>
import SumaryFinalReportPdf from "./sumary-final-report-pdf";
import SumaryDailyReportPdf from "./sumary-daily-report-pdf";
import debounce from "lodash/debounce";

//Data
function data() {
  return {
    pageInfo: {
      TpVessel: '',
      itinerarySelected: {},
      Visit: '',
      Date: '',
      CraneAlias: '',
      Report: 1,
    },
    Header: {},
    Embarked: {},
    Movement: {},
    Total:{
      LapseTon: 0,
    },
    Turn: [],
    TurnCraneJson: [],
    Lapse: [],
    Position: [],
    Condition: 0,
    apiStateLoading: false,
  };
}

//Created
function created() {
  this.apiStateLoading = true;
  let info = JSON.parse(localStorage.getItem("pageInfo"));

  if (!info) {
    window.close();
    return;
  }

  this.pageInfo = { ...this.pageInfo, ...info };

  if (this.pageInfo.Report==1) {
    this.getBulkCarrierDailyReport(this.pageInfo);
  } else {
    this.getBulkCarrierFinalReport(this.pageInfo)
  }
  

  localStorage.removeItem("pageInfo");

  window.addEventListener("beforeprint", function(event) {
    // document.title = `FULL CARGO PLAN`;
  });
  window.addEventListener("afterprint", function(event) {
    close();
  });
}

//Methods:
function getBulkCarrierDailyReport(pageInfo) {

  let ReportJson = {
    VisitId: pageInfo.Visit,
    DateReport: pageInfo.Date,
    VisitCraneId: pageInfo.VisitCraneId,
  };
  this.$http.post("VisitBulkCarrierDailyReport", ReportJson, { root: 'ReportJson' } )
    .then(response => {       
      let list = response.data.data[0];
      this.Header = list.HeaderJson[0];
      this.Embarked = list.EmbarkedJson[0];
      this.Movement = list.MovementJson[0];
      let LapseIndex = list.EmbarkedByLapseJson;
      this.Position = list.PositionJson;

      let TurnIndex = list.TurnCraneJson;

      this.LapseLength(LapseIndex);
      this.TurnLength(TurnIndex);

      if (this.Lapse.length == this.Turn.length) {
        this.Condition = this.Lapse.length;
      } else {
        if (this.Lapse.length > this.Turn.length) {
          this.Condition = this.Lapse.length;
        } else {
          this.Condition = this.Turn.length;
        }
      }
      this.apiStateLoading = false;
    }).catch( err => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function getBulkCarrierFinalReport(pageInfo) {

  let ReportJson = {
    VisitId: pageInfo.Visit,
    VisitCraneId: pageInfo.VisitCraneId,
  };
  this.$http.post("VisitBulkCarrierFinalReport", ReportJson, { root: 'ReportJson' } )
    .then(response => {       
      let list = response.data.data[0];
      this.Header = list.HeaderJson[0];
      this.Embarked = list.EmbarkedCargoJson[0];
      this.Movement = list.MovementJson[0];
      let LapseIndex = list.EmbarkedByLapseJson;
      this.Position = list.PositionJson;
    
      this.LapseLength(LapseIndex);

      this.Condition = this.Lapse.length;
      this.apiStateLoading = false;
    }).catch( err => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function LapseLength(LapseIndex) {
  if (LapseIndex.length > 18) {
    let index = Math.ceil(LapseIndex.length/18) == 0 ? 1 : Math.ceil(LapseIndex.length/18);
    let leng = 0;
    let ObjectLapse = [];
    for (let n = 0; n < index; n++) {
      for (let i = 0; i < 18 && LapseIndex.length > leng; i++) {
        ObjectLapse.push(LapseIndex[leng]);
        this.Total.LapseTon += LapseIndex[leng].Ton;
        if (i == 17 || (LapseIndex.length-1) == leng) {
          this.Lapse.push({
            index: ObjectLapse,
          });
          ObjectLapse = [];
        }
        leng++;
      }
    }
  } else {
    for (let index = 0; index < LapseIndex.length; index++) {
      this.Total.LapseTon += LapseIndex[index].Ton;
    }
    this.Lapse.push({
      index: LapseIndex,
    });
  }
}

function TurnLength(TurnIndex) {

  let TurnCondition = false;
  let cont = 0;

  for (let index = 0; index < TurnIndex.length; index++) {
    if (TurnIndex[index].CraneJson.length > 12 && cont < TurnIndex[index].CraneJson.length) {
      cont = TurnIndex[index].CraneJson.length;
      TurnCondition = true;
    }
  }

  if ( TurnCondition == true) {
    let turn_1 = 0;
    let turn_2 = 0;
    let turn_3 = 0;
    let turn_4 = 0;
    let index = Math.ceil(cont/12) == 0 ? 1 : Math.ceil(cont/12);
    let ObjectLapse = [];
    let turnTotal = [];

    for (let n = 0; n < index; n++) {

      for (let b = 0; b < TurnIndex.length; b++) {
        if (b==0) {
          for (let i = 0; i < 12 && TurnIndex[b].CraneJson.length > turn_1; i++) {
            ObjectLapse.push(TurnIndex[b].CraneJson[turn_1]);
            if (i == 11 || (TurnIndex[b].CraneJson.length-1) == turn_1) {
              turnTotal.push({

                  CraneJson: ObjectLapse,
                  IdX: TurnIndex[b].IdX,
                  TurnId: TurnIndex[b].TurnId,
                  TurnNameEn: TurnIndex[b].TurnNameEn,
                  TurnNameEs: TurnIndex[b].TurnNameEs,
                  TotalMov: TurnIndex[b].TotalMov,
                  TotalTon: TurnIndex[b].TotalTon,
              });
              ObjectLapse = [];
            }
            turn_1++;
          }
        }

        if (b==1) {
          for (let i = 0; i < 12 && TurnIndex[b].CraneJson.length > turn_2; i++) {

            ObjectLapse.push(TurnIndex[b].CraneJson[turn_2]);
            if (i == 11 || (TurnIndex[b].CraneJson.length-1) == turn_2) {
              turnTotal.push({

                  CraneJson: ObjectLapse,
                  IdX: TurnIndex[b].IdX,
                  TurnId: TurnIndex[b].TurnId,
                  TurnNameEn: TurnIndex[b].TurnNameEn,
                  TurnNameEs: TurnIndex[b].TurnNameEs,
                  TotalMov: TurnIndex[b].TotalMov,
                  TotalTon: TurnIndex[b].TotalTon,
              });
              ObjectLapse = [];
            }
            turn_2++;
          }
        }

        if (b==2) {
          for (let i = 0; i < 12 && TurnIndex[b].CraneJson.length > turn_3; i++) {

            ObjectLapse.push(TurnIndex[b].CraneJson[turn_3]);
            if (i == 11 || (TurnIndex[b].CraneJson.length-1) == turn_3) {
              turnTotal.push({
                  CraneJson: ObjectLapse,
                  IdX: TurnIndex[b].IdX,
                  TurnId: TurnIndex[b].TurnId,
                  TurnNameEn: TurnIndex[b].TurnNameEn,
                  TurnNameEs: TurnIndex[b].TurnNameEs,
                  TotalMov: TurnIndex[b].TotalMov,
                  TotalTon: TurnIndex[b].TotalTon,
              });
              ObjectLapse = [];
            }
            turn_3++;
          }
        }
        
        if (b==3) {
          for (let i = 0; i < 12 && TurnIndex[b].CraneJson.length > turn_4; i++) {

            ObjectLapse.push(TurnIndex[b].CraneJson[turn_4]);
            if (i == 11 || (TurnIndex[b].CraneJson.length-1) == turn_4) {

              turnTotal.push({
                  CraneJson: ObjectLapse,
                  IdX: TurnIndex[b].IdX,
                  TurnId: TurnIndex[b].TurnId,
                  TurnNameEn: TurnIndex[b].TurnNameEn,
                  TurnNameEs: TurnIndex[b].TurnNameEs,
                  TotalMov: TurnIndex[b].TotalMov,
                  TotalTon: TurnIndex[b].TotalTon,
              });
              ObjectLapse = [];
            }
            turn_4++;
          }
          this.Turn.push({
            index: turnTotal,
          });
          turnTotal = [];
        }

        if (TurnIndex[b].CraneJson.length == 0) {
          turnTotal.push({
            CraneJson: [],
            IdX: TurnIndex[b].IdX,
            TurnId: TurnIndex[b].TurnId,
            TurnNameEn: TurnIndex[b].TurnNameEn,
            TurnNameEs: TurnIndex[b].TurnNameEs,
            TotalMov: TurnIndex[b].TotalMov,
            TotalTon: TurnIndex[b].TotalTon,
          });
        }

      }
      
    }

  }else {
    this.Turn.push({
      index: TurnIndex,
    });
  }
}

//Computed:

export default {
  name: "sumary-report-PDF",
  components: {
    SumaryFinalReportPdf,
    SumaryDailyReportPdf,
  },
  data,
  created,
  updated: debounce(function () {
    this.$nextTick(() => {
      window.print();
    });
  }, 250),
  methods: {
    LapseLength,
    TurnLength,
    getBulkCarrierDailyReport,
    getBulkCarrierFinalReport,
  },
  computed: {},
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>